export default {
  '-1': 'Failed to open file',
  '-2': 'Failed to creater file',
  '-3': 'Failed to read file',
  '-4': 'Failed to write over file',
  '-5': 'Failed to reserve operation memory',
  '-6': 'Symbol data is too big',
  '-7': 'Bitmap image format is invalid',
  '-8': 'There is image that out of supported format of bitmap image',
  '-9': 'Bitmap image type is wrong',
  '-10': 'Something wrong in bitmap buffer parameter',
  '-11': 'Symbol image is too small',
  '-12': 'Failed to analysis T-mark',
  '-13': 'Something wrong in symbol block number',
  '-14': 'Number of block in top, bottom, left, right is not matched',
  '-15': 'Can not recognize as rotated symbol',
  '-16': 'No symbol header data',
  '-17': 'Failed to fix acquisition, error of symbol header',
  '-18': 'Failed to fix acquisition, error of body data',
  '-19': 'Symbol header type value is invalid',
  '-20': 'Symbol header  revision value is invalid',
  '-21': 'Symbol header compressed information value is invalid',
  '-22': 'Symbol header licence ID information is invalid',
  '-23': 'Symbol header data word number is invalid',
  '-24': 'Decode output destination/ operation buffer was over',
  '-25': 'Because body type is invalid, it becomes reference out of body type',
  '-26': 'Decompression failed because of invalid compression data',
  '-27': 'Sub data decompression failed because of invalid decompress result data',
  '-28': 'Multi-language text of invalid text type is detected.',
  '-29': 'Decompression is failed because of invalid sub data',
  '-30': 'Multi-language text data header is invalid and out of text access',
  '-31': 'Symbol size parameter is invalid',
  '-32': 'Symbol type parameter of symbol is invalid',
  '-33': 'Symbol error fixing strength parameter is invalid',
  '-34': 'Print density parameter is invalid',
  '-35': 'Cell size parameter is invalid',
  '-36': 'Cell format is invalid ',
  '-37': 'Black brightness parameter is invalid',
  '-38': 'Data amount is too large, could not store in symbol of specified symbol size ',
  '-50': 'There is invalid definition file supports language name => text type',
  '-51': 'There is invalid language name specification of definition file supports language name => text type',
  '-52': 'There is invalid text type specification of definition file supports language name => text type',
  '-53': 'There is invalid data after text type specification of definition file supports language name => text type',
  '-54': 'Over maximum amount of definition support language name => text type',
  '-55': 'There is invalid character in comment description of  multi-language text description with ASCII tag and file ending is detected in comment.',
  '-56': 'There is data which is not tab or blank in line same as comment line in multi-language text description with ASCII tag',
  '-57': 'In multi-language text description with ASCII tag, there is no <tag ..>, so text data is detected ',
  '-58': 'In multi-language text description with ASCII tag, detected  tag that is not <tag ..>',
  '-59': 'In the middle of <tag ...> definition in multi-language text description with ASCII tag, file termination is detected ',
  '-60': 'lang attribute definition of <tag ...> definition in multi-language text description with ASCII tag is invalid',
  '-61': 'size attribute definition of <tag ...> definition in multi-language text description with ASCII tag is invalid',
  '-62': 'type attribute definition of <tag ...> definition in multi-language text description with ASCII tag is invalid',
  '-63': 'del_nl attribute definition of <tag ...> definition in multi-language text description with ASCII tag is invalid',
  '-64': 'Detected invalid attribute definition in definition of <tag ...> definition in multi-language text description with ASCII tag',
  '-65': 'There is no lang attribute definition in definition of <tag ...> definition in multi-language text description with ASCII tag',
  '-66': 'There is data that is not blank, tab in line same as line <tag ...> definition in multi-language text description with ASCII tag',
  '-67': 'In multi-language text description with ASCII tag, specified size data in attribution is not saved',
  '-68': 'In multi-language text description with ASCII tagã€in size attribution,  right after specified size text is not line ending , same line but line middle',
  '-69': 'In multi-language text description with ASCII tag, could not find support termination tag </tag>',
  '-70': 'There is blank in text data of multi-language text description with ASCII tag',
  '-71': 'There is invalid character  in text data of multi-language text description with ASCII tag',
  '-72': 'Conversion from text data of multi-language text description is over destination buffer',
  '-73': 'Specified hex attribution of <tag ...> in text data of multi-language text description is invalid',
  '-74': 'Specified attr attribution of <tag ...> in text data of multi-language text description is invalid',
  '-99': 'There is process that unimplemented',
  '-100': 'Data input error',
  'request entity too large': 'There are too many characters. Please change the code size and try again.'
}