import serverError from './server-error-en';

export default {
  ...serverError,
  format: {
    scheduling: '*Staring time must be greater than {minSending}',
    repeat: 'No repeat | 1 time | {count} times',
    Repeatedly: 'No repeat | Repeat times: 1 time | Repeat times: {count} times',
    autoPushAfter:
      'Auto push must be after {minSending} minutes from the time the code is first generated.'
  },
  menu: {
    Company: 'Company',
    Projects: 'Projects',
    Message: 'Messages',
    Settings: 'Settings'
  },
  message: {
    grid: {
      ID: 'ID',
      Content: 'Title And Content',
      Sender: 'Sender',
      Status: 'Status',
      Scheduling: 'Scheduling',
      Target: 'Target',
      Created: 'Created',
      Updated: 'Updated',
      Actions: 'Actions',
      Languages: 'Languages',
      Attachments: 'Attachments',
      Title: 'Title',
      MessageID: 'Message ID',
      Sending: 'Sending',
      Like: 'Like'
    },
    compose: {
      'End time': 'End time',
      'Please pick a date': 'Please pick a date',
      Daily: 'Daily',
      Weekly: 'Weekly',
      Monthly: 'Monthly',
      Advance: 'Advanced setting',
      'Daily message single': 'Every day, {time}',
      'Daily message multi': 'Every {repeat} days, {time}',
      'Weekly message single': 'Weekly on {days}, {time}',
      'Weekly message multi': 'Every {repeat} weeks on {days}, {time}',
      'Monthly message single': 'Monthly on day {days}, {time}',
      'Monthly message multi': 'Every {months} on day {days}, {time}',
      Last: 'Last',
      Weeks: 'Weeks',
      Days: 'Days',
      Months: 'Months',
      On: 'On',
      'Repeat every': 'Repeat every',
      Custom: 'Custom',
      ImageIsRequired: 'Image is required',
      ImageIsInvalid: 'Image is invalid',
      PDFIsRequired: 'PDF is required',
      PDFIsInvalid: 'PDF is invalid',
      URLIsRequired: 'URL is required',
      URLIsInvalid: 'URL is invalid'
    }
  },
  project: {
    grid: {
      Name: 'Name',
      Active: 'Status',
      Created: 'Created',
      Updated: 'Updated',
      Actions: 'Actions',
      PublishSetting: 'Publish setting',
      EditWebsite: 'Edit website',
      Photo: 'Photo',
      Description: 'Description',
      Template: 'Template',
      Path: 'Path',
      URL: 'URL'
    }
  },
  page: {
    grid: {
      Name: 'Name'
    }
  },
  code: {
    grid: {
      ID: 'ID',
      Photo: 'Photo',
      Title: 'Title',
      Type: 'Type',
      Status: 'Status',
      Created: 'Created',
      Updated: 'Updated',
      CodeID: 'Code ID',
      CreatedDate: 'Created date',
      EditWebsite: 'Edit website',
      PublishSetting: 'Publish setting',
      Category: 'Category'
    },
    compose: {
      previewCodeFailed:
        'Data amount is too large, could not store in symbol of specified symbol size'
    },
    codeSize: {
      XS: 'Size XS (3*3 block symbol)',
      S: 'Size S (6*6 block symbol)',
      M: 'Size M (9*9 block symbol)',
      L: 'Size L (10*10 block symbol)',
      XL: 'Size XL (12*12 block symbol)'
    },
    errorLevel: {
      L: 'L: EC error level low (10% redundancy)',
      M: 'M: EC error level medium (20% redundancy)',
      H: 'H: EC error level high (40% redundancy)'
    },
    importCode: {
      'Are you sure to generate code?': 'Are you sure to generate code?'
    }
  },
  billing: {
    grid: {
      Id: 'ID',
      Total: 'Total',
      Status: 'Status',
      InvoiceDate: 'Invoice date',
      Updated: 'Updated'
    }
  },
  account: {
    detail: {
      codeCreateFeeRequired: 'Please enter the code creation fee.',
      priceRequired: 'Price is required',
      countRequired: 'Count is required',
      voiceCode: 'Voice code',
      naviCode: 'Navi code',
      spotCode: 'Spot code',
      mixCode: 'Mix code'
    }
  },
  PortalSite: {
    Grid: {
      Name: 'Name',
      CreatedDate: 'Created date',
      PublishSetting: 'Publish setting',
      EditWebsite: 'Edit website',
      Actions: 'Actions',
      MobileActive: 'Mobile active',
      Photo: 'Photo',
      Description: 'Description'
    }
  },
  'Log out': 'Log out',
  Cancel: 'Cancel',
  'Uni-Voice for UD': 'Uni-Voice for UD',
  Title: 'Title',
  'Code name': 'Code name',
  'Title is required': 'Title is required',
  'Title max 65 length': 'Title max 65 length',
  'Title And Content': 'Content',
  Content: 'Content',
  'Content is required': 'Content is required',
  TitleMaxLength: 'Title max {length} length',
  ContentMaxLength: 'Content max {length} length',
  siteName: 'siteName',
  Total: 'Total',
  TotalMoney: 'Total',
  'No data': 'No data',
  'Rejected reason': 'Rejected reason',
  'Message content': 'Message content',
  Next: 'Next',
  Targeting: 'Targeting',
  'A valid targeting condition is required': 'A valid targeting condition is required',
  Scheduling: 'Scheduling',
  'Save as draft': 'Save as draft',
  Submit: 'Submit',
  'Compose Submit': 'Submit',
  Error: 'Error',
  Success: 'Success',
  'Push has been saved.': 'Push has been saved.',
  Status: 'Status',
  Search: 'Search',
  Reset: 'Reset',
  'New Compose': 'New Compose',
  Target: 'Target',
  Created: 'Created',
  Updated: 'Updated',
  Actions: 'Actions',
  'Message statistic': 'Message statistic',
  'Gender distribution': 'Gender distribution',
  'No data available.': 'No data available.',
  'City distribution': 'City distribution',
  City: 'City',
  Count: 'Count',
  'Age distribution': 'Age distribution',
  Age: 'Age',
  'Progression by time': 'Progression by time',
  Attachment: 'Attachment',
  'URL is not valid': 'URL is not valid',
  'Add languages': 'Add languages',
  Select: 'Select',
  Switch: 'Switch',
  Gender: 'Gender',
  Location: 'Location',
  'All users': 'All users',
  'Starting time': 'Starting time',
  'Select Time': 'Select Time',
  'Repeatedly Times': 'Repeatedly Times',
  'Duration (hours):': 'Duration (hours):',
  hours: 'hours',
  'Please select duration': 'Please select duration',
  Upload: 'Upload',
  'Image must smaller than 10MB!': 'Image must smaller than 10MB!',
  Male: 'Male',
  Female: 'Female',
  Others: 'Others',
  Start: 'Start',
  Repeat: 'Repeat',
  Duration: 'Duration',
  'View statistics': 'View statistics',
  Edit: 'Edit',
  'Company System': 'Company System',
  'Company Push Only': 'Notification System',
  'Company Full Access': 'Uni-Voice for UD',
  'Content management system': 'Content management system',
  'Account information': 'Account information',
  Email: 'Email',
  Profile: 'Profile',
  'Email Settings': 'Account',
  'setting-email': 'Account',
  'Contact phone Settings': 'Contact phone',
  'Company name Settings': 'Company name',
  'Company address Settings': 'Company address',
  'Company URL Settings': 'Company URL',
  'Descriptions Settings': 'Descriptions',
  'Please input your E-mail!': 'Please input your E-mail!',
  'The input is not valid E-mail!': 'The input is not valid E-mail!',
  'Enter your email': 'Enter your email',
  'Contact email': 'Contact email',
  'Contact phone': 'Contact phone',
  'Please enter a phone number': 'Please enter a phone number',
  'Company name': 'Company name',
  'Company name placeholder': 'Company name',
  'Please enter company name': 'Please enter company name',
  'Company address': 'Company address',
  'Please enter company address': 'Please enter company address',
  'Your registration has been received.': 'Your registration has been received.',
  'We will approve and send your account password to email ':
    'We will approve and send your account password to email ',
  'Go to Sign in': 'Go to Sign in',
  'The token is expired or does not exist': 'The token is expired or does not exist',
  Registration: 'Registration',
  'Already have account?': 'Already have account?',
  'Sign in': 'Sign in',
  Register: 'Register',
  'Please check your email.': 'Please check your email.',
  'We have sent registation form to your entered email':
    'We have sent registation form to your entered email',
  'Email is already exists. Please use other email.':
    'Email is already exists. Please use other email.',
  'Reset your password': 'Reset your password',
  'Please input verification code': 'Please input verification code',
  'Reset code': 'Reset code',
  Resend: 'Resend',
  'At least 8-digit password, case sensitive.': 'At least 8-digit password, case sensitive.',
  'New Password': 'New Password',
  'Send code': 'Send code',
  'Sign In': 'Sign In',
  'Change password has been successful': 'Change password has been successful',
  'Reset code is being sent...': 'Reset code is being sent...',
  Notice: 'Notice',
  'Please update your password': 'Please update your password',
  'Please enter your password': 'Please enter your password',
  'Enter your password': 'Enter your password',
  'Please input confirm password': 'Please input confirm password',
  'Confirm password': 'Confirm password',
  'Two passwords are inconsistent': 'Two passwords are inconsistent',
  'Don’t have account?': 'Don’t have account?',
  'Forget your password?': 'Forget your password?',
  'Reset password?': 'Reset password?',
  'Login failed': 'Login failed',
  'Change password': 'Change password',
  'setting-change-password': 'Change password',
  Image: 'Image',
  'PDF Attachment': 'PDF Attachment',
  'Target Users': 'Target Users',
  'Add Filter': 'Add Filter',
  All: 'All',
  Waiting: 'Waiting',
  Pending: 'Pending',
  Rejected: 'Rejected',
  Approved: 'Approved',
  Sending: 'Sending',
  Done: 'Done',
  Draft: 'Draft',
  'Gender of user': 'Gender of user',
  'Age of user': 'Age of user',
  'Location to which the user belongs': 'Location to which the user belongs',
  female: 'female',
  male: 'male',
  other: 'other',
  ALL: 'ALL',
  WAITING: 'WAITING',
  PENDING: 'PENDING',
  DONE: 'DONE',
  DRAFT: 'DRAFT',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  SENDING: 'SENDING',
  CANCEL: 'CANCEL',
  PUBLISHED: 'PUBLISHED',
  'PUBLISHED(edited)': 'PUBLISHED(edited)',
  'PUBLISHED(updating)': 'PUBLISHED(updating)',
  ABOLISHED: 'ABOLISHED',
  FAILED: 'FAILED',
  Home: 'Home',
  Company: 'Company',
  Projects: 'Projects',
  Message: 'Message',
  'Import File Message': 'Message',
  'Message list': 'Message list',
  'Compose message': 'Compose message',
  'Message detail': 'Message detail',
  'Message Statistic': 'Message Statistic',
  Settings: 'Settings',
  'Email Setting': 'Email Setting',
  'Change Password': 'Change Password',
  'Menu Change Password': 'Change password',
  'Network error': 'Network error',
  'Authorization verification failed': 'Authorization verification failed',
  'Server error, please try again later.': 'Server error, please try again later.',
  and: 'and',
  'Please select': 'Please select',
  Option: 'Option',
  items: 'items',
  days: 'days',
  month: 'month',
  'There is a problem with the uploaded file. This file will not be uploaded':
    'There is a problem with the uploaded file. This file will not be uploaded',
  'Code data': 'Code data',
  'Title code demo text': 'Title code demo text',
  'Point Name': 'Point name',
  'Point Location': 'Location',
  'Point Categories': 'Categories',
  'Point Please select categories': 'Please select categories',
  'Point Description': 'Description',
  'Point Action': 'Action',
  'Save Point': 'Save',
  'Please input point name in english': 'Please input point name in English',
  'Please input point name in japanese': 'Please input point name in Japanese',
  'Please input point name in chinese': 'Please input point name in Chinese',
  'Please input point name in korean': 'Please input point name in Korean',
  'Please input your latitude': 'Please input your Latitude',
  'Please input your longitude': 'Please input your Longitude',
  'Latitude is invalid': 'Latitude is invalid',
  'Longitude is invalid': 'Longitude is invalid',
  'Required choose at least a category': 'Required choose at least a category',
  '6 at most': '6 at most',
  Manual: 'Manual',
  'Import File': 'Import File',
  'Code size': 'Code size',
  'EC error level': 'EC error level',
  'Add Code from Mobile': 'Add Code from Mobile',
  'Add Code': 'Add Code',
  Save: 'Save',
  'Save project': 'Save',
  'Change Project': 'Save',
  'Please input your project name': 'Please input your project name',
  DuplicateSuccess: 'The code has been copied.',
  DeletedSuccess: 'The code has been deleted.',
  Generate: 'Generate',
  'Re-Generate': 'Re-Generate',
  Abolished: 'Abolish',
  Published: 'Published',
  Failed: 'Failed',
  'Published(edited)': 'Published(edited)',
  'Published(updating)': 'Published(updating)',
  'Created date': 'Created date',
  'View code list': 'View code list',
  'Created Project': 'The project has been created.',
  'Add Point': 'Add Point',
  'Code list': 'Code list',
  'code-list': 'Code list',
  'Code List': 'Code List',
  Codes: 'Codes',
  Remove: 'Remove',
  'Your location': 'Your location',
  Type: 'Type',
  'Import Type': 'Select code type',
  'Import Code Label': 'Add code from Mobile',
  Navi: 'Navi',
  Faci: 'Faci',
  'Code Name': 'Code Name',
  'Code Content': 'Code Content',
  'Enter code name in english': 'Enter code name in english',
  ENG: 'ENG',
  'Enter code name in japanese': 'Enter code name in japanese',
  JPN: 'JPN',
  'Enter code name in chinese': 'Enter code name in chinese',
  CHI: 'CHI',
  'Enter code name in korean': 'Enter code name in korean',
  KOR: 'KOR',
  Preview: 'Preview',
  'Compose project': 'Compose project',
  'project-compose': 'Compose project',
  'Created project is success': 'Created project is success',
  'Project compose': 'Project compose',
  Compose: 'Project compose',
  'Code compose': 'Code compose',
  'Compose code': 'Compose code',
  'Compose voice code': 'Compose voice code',
  NotFoundCodeById:
    'No instance with id {count} found for Code |No instance with id {count} found for Code | No instance with id {count} found for Code',
  SaveDraftSuccess: 'Save as draft',
  SaveGenerateSuccess: 'I have had the code posted.',
  SaveAbolishedSuccess: 'The code has been disabled.',
  'Edit code': 'Edit code',
  'code-detail': 'Code detail',
  'voice-code-detail': 'Code detail',
  Name: 'Name',
  Active: 'Active',
  'Edit Code': 'Edit Code',
  Project: 'Project',
  Duplicate: 'Duplicate',
  'Code Preview': 'Code Preview',
  'Are you sure to duplicate this code?': 'Are you sure to duplicate this code?',
  ID: 'ID',
  'Code type': 'Code type',
  'Code Type': 'Code Type',
  'Create date': 'Create date',
  'Updated date': 'Updated date',
  Download: 'Download',
  'Switch Project': 'Switch Project',
  'Select code to import': 'Add code',
  Import: 'Import',
  Loading: 'Loading',
  'Loading...': 'Loading...',
  VALIDATION_FAILED: 'There was an error in the file. Please check again.',
  ENCODE_ERROR: 'Data amount is too large. Please try again.',
  UpdateProjectSuccess: 'Update project is successful',
  InvalidPhone: 'Please enter in the format of 10-11 single digit numbers (no hyphens)',
  View: 'View',
  ViewCell: 'View',
  ChangeView: 'View',
  Delete: 'Delete',
  Archive: 'Archive',
  'Click or drag file TYPES to this area to upload':
    'Click or drag file {types} to this area to upload',
  'File must smaller than 10MB': 'File must smaller than 10MB',
  'File must smaller than 50MB': 'File must smaller than 50MB',
  'No any code need to generate': 'No any code need to generate',
  Ready: 'Ready',
  Processing: 'Processing',
  Publish: 'Publish',
  Successful: 'Successful',
  'These codes are generated': 'These codes are generated',
  'Generate error': 'There were error in the generated codes. Please check.',
  'Cannot generate code': 'Cannot generate code',
  'Enter old password': 'Enter old password',
  'Enter new password': 'Enter new password',
  'Place holder': 'place holder',
  'Confirm new password': 'Confirm new password',
  'Password length at least 8 characters': 'Password length at least 8 characters',
  'Confirm password is not right!': 'Confirm password is not right',
  Update: 'Update',
  'Please input your old password!': 'Please input your old password!',
  'Code name is required.': 'Code name is required.',
  'Pick a location': 'Pick a location',
  'Company URL': 'Company URL',
  Descriptions: 'Descriptions',
  'Please input your Company Address!': 'Please input your Company Address',
  'Please input your Company Name!': 'Please input your Company Name',
  'Please input your Company URL!': 'Please input your Company URL!',
  'Please input your Descriptions!': 'Please input your Descriptions!',
  'Project name': 'Project name',
  'Project list': 'Project list',
  'Project name max length 50': 'Please enter a project name of 50 characters or less',
  voice_code: 'Voice Code',
  spot_code: 'Spot Code',
  Spot: 'Spot',
  navi_code: 'Navi Code',
  mix_code: 'Mix Code',
  'Incorrect password': 'Incorrect password',
  'De-active': 'De-active',
  'Confirm password is required!': 'Confirm password is required!',
  'Edit project': 'Edit project',
  'project-detail': 'Project detail',
  ChangeEmailSuccess: 'Your profile has been changed successfully.',
  'Enter a location': 'Please enter the location.',
  'Are you sure to delete this code': 'Are you sure to delete this code？',
  'Would you like to duplicate this code？':
    'Would you like to duplicate this code？It will be saved as a draft.',
  'Are you sure to generate code?': 'Are you sure to generate code?',
  'Are you sure to re-generate code ?': 'Are you sure to re-generate code ?',
  Yes: 'Yes',
  No: 'No',
  'Confirmation code is wrong. Please try again.': 'Confirmation code is wrong. Please try again.',
  'Please input URL format': 'Please input valid URL (for example: https://example.com)',
  LAT: 'LAT',
  LONG: 'LONG',
  Lat: 'Lat',
  Long: 'Long',
  'Message title': 'Message title',
  'Total amount': 'Total amount',
  'Invoice date': 'Invoice date',
  'Issued date': 'Issued date',
  'Paid date': 'Paid date',
  'Unit price': 'Unit price',
  CodeQuantity: 'Quantity',
  PushQuantity: 'Quantity',
  'Voice code': 'Voice code',
  'Navi code': 'Navi code',
  'Mix code': 'Mix code',
  'Spot code': 'Spot code',
  'Sub total': 'Sub total',
  'On Going': 'On Going',
  ON_GOING: 'On Going',
  Paid: 'Paid',
  PAID: 'Paid',
  Unpaid: 'Unpaid',
  UNPAID: 'Unpaid',
  ISSUED: 'ISSUED',
  Id: 'ID',
  'Choose a code type to compose': 'Choose a code type to compose',
  'Voice code(single/multiples)': 'Voice code(single/multiples)',
  'Voice code with plain content(single/multiples)':
    'Voice code with plain content(single/multiples)',
  'Voice code with rich content(single/multiples)':
    'Voice code with rich content(single/multiples)',
  'Navi code / Spot code / Mix code': 'Navi code / Spot code / Mix code',
  Ok: 'OK',
  'Language is required': 'Language is required',
  'Add Language': 'Add Language',
  Language: 'Language',
  'This code is created': 'This code is created',
  'File name': 'File name',
  'There was an error in the generated code. please confirm.':
    'There was an error in the generated code. please confirm.',
  Billings: 'Billings',
  'Billing list': 'Billing list',
  Back: 'Back',
  'View Bill': 'View Bill',
  'This message is updated': 'This message is updated',
  'Updated message is fail': 'Updated message is fail',
  'Searching zip code is success': 'Searching zip code is success',
  'Searching zip code is fail': 'Searching zip code is fail',
  'Person in charge': 'Person in charge',
  'Zip code': 'Zip code',
  Prefecture: 'Prefecture',
  'Roll Back': 'Return to draft',
  'Please enter company prefecture': 'Please enter prefecture',
  'Please enter person in charge': 'Please enter person in charge',
  'Please enter company city': 'Please enter company city',
  'Zip code is 7 length': 'Zip code is 7 length',
  'Are you sure to publish this message now ?': 'Are you sure to publish this message now ?',
  'Please enter company url': 'Please enter company url',
  'Please enter descriptions': 'Please enter descriptions',
  'Company url': 'Company url',
  'Are you sure to cancel publishing this message?':
    'Are you sure to cancel publishing this message?',
  'Zip code is required': 'Zip code is required',
  'There is no applicable address.': 'There is no applicable address.',
  'All Rights Reserved.': 'All Rights Reserved.',
  'App Target': 'App Target',
  'User can push to user app': 'User can push to user app',
  'Repeated Times': 'Repeated Times',
  End: 'End',
  'This message is deleted': 'This message is deleted',
  'Deleted message is fail': 'Deleted message is fail',
  'Are you sure delete this message': 'Are you sure delete this message',
  'File must be PDF Format!': 'File must be PDF Format!',
  'File must be Bitmaps Image Format!': 'File must be Bitmaps Image Format!',
  Both: 'Both',
  'Please set repeatly lower than 10': 'Please set repeatly lower than 10',
  AppTarget: 'App target',
  'Advanced setting schedule': 'Advanced setting schedule',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',
  'Please set a repeat': 'Please set a repeat',
  'Please set days': 'Please set days',
  'Please set month': 'Please set month',
  'Password at least number, uppercase and lowercase letter, and at least 8':
    'Password at least number, uppercase and lowercase letter, and at least 8',
  'Are you sure to return this message to draft?': 'Are you sure to return this message to draft?',
  'Location exceed maximum': 'Too many locations, please select less than 20',
  'File must be Image Format!': 'File must be Image Format!',
  English: 'English',
  Japanese: 'Japanese',
  Korean: 'Korean',
  Vietnamese: 'Vietnamese',
  'Chinese(Simplified)': 'Chinese(Simplified)',
  'Simplified Chinese': 'Simplified Chinese',
  'Traditional Chinese': 'Traditional Chinese',
  French: 'French',
  German: 'German',
  Spanish: 'Spanish',
  Italian: 'Italian',
  Portuguese: 'Portuguese',
  Russian: 'Russian',
  Thai: 'Thai',
  Indonesian: 'Indonesian',
  Arabic: 'Arabic',
  Malay: 'Malay',
  Hindi: 'Hindi',
  Tagalog: 'Tagalog',
  Dutch: 'Dutch',
  'At least 8 characters': 'At least 8 characters',
  'Include number': 'Include number',
  'Include upper character': 'Include upper character',
  'Invalid start time. Please try again.': 'Invalid start time. Please try again.',
  'This message is rollbacked': 'This message is rollbacked',
  'This message has been cancelled': 'This message has been cancelled',
  'Invalid title': 'Invalid title',
  'Invalid content': 'Invalid content',
  'Missing default language': 'Missing default language',
  'Invalid attachment': 'Invalid attachment',
  'Invalid qrcode link': 'Invalid qrcode link',
  'Invalid gender': 'Invalid gender',
  'Number location is too large, must <= 20': 'Number location is too large, must <= 20',
  'Invalid app target': 'Invalid app target',
  'Invalid ending time': 'Invalid ending time',
  'Invalid daily schedule': 'Invalid daily schedule',
  'Invalid weekly schedule': 'Invalid weekly schedule',
  'Invalid repeat number': 'Invalid repeat number',
  'Invalid weekday': 'Invalid weekday',
  'Invalid monthly schedule': 'Invalid monthly schedule',
  'Invalid day of month': 'Invalid day of month',
  'Invalid month': 'Invalid month',
  'Not support this frequency': 'Not support this frequency',
  'Push message currently disabled': 'Push message currently disabled',
  'Invalid starting_time': 'Invalid starting_time',
  'A verification code for resetting has been sent. Please check your email.':
    'A verification code for resetting has been sent. Please check your email.',
  Information: 'Information',
  'Token is expired. Are you login again?': 'Token is expired. Are you login again?',
  'Add attachment': 'Add attachment',
  ja: 'Japanese',
  en: 'English',
  ko: 'Korean',
  zh: 'Traditional Chinese',
  url: 'URL',
  URL: 'URL',
  pdf: 'PDF',
  picture: 'Image',
  '(lat,lon) must not be emtpy': '(lat,lon) must not be emtpy',
  '(lat,lon) must be number': '(lat,lon) must be number',
  '1st category must not be empty': '1st category must not be empty',
  'Category must be number': 'Category must be number',
  'Point name must not be empty': 'Point name must not be empty',
  '1st language must not be emtpy': '1st language should not be emtpy',
  'Code name must not be empty': 'Code name must not be empty',
  'Navi Type should be number': 'Navi Type should be number',
  Categories: 'Categories',
  'Push billing': 'Push billing',
  'Push option': 'Push option',
  Price: 'Price',
  'Current month': 'Current month',
  'Next month': 'Next month',
  'Auto sending': 'Auto sending',
  'Auto sending option': 'Auto sending option',
  'Code billing': 'Code billing',
  'Code pricing plan': 'Code pricing plan',
  Subscription: 'Subscription',
  'Pay as you go': 'Pay as you go',
  Issued: 'Issued',
  Amount: 'Amount',
  'Push type': 'Push type',
  'Message pricing plan': 'Message pricing plan',
  Tax: 'Tax',
  'Show demo': 'Show demo',
  RemainPush: 'There are {count} remaining pushes in this month',
  'Data amount is too large, could not store in symbol of specified symbol size':
    'Data amount is too large, could not store in symbol of specified symbol size',
  'Download invoice': 'Download invoice',
  'Account deactivated': 'Account deactivated',
  "Sorry, you haven't paid the bill of last month so you cannot use service. Please pay the bill for continue using our service":
    "Sorry, you haven't paid the bill of last month so you cannot use service. Please pay the bill for continue using our service",
  Logout: 'Logout',
  Comma: ',',
  'You reached number of pushes in month. Other messages which are in sending status will not be pushed until next month.':
    'You reached number of pushes in month. Other messages which are in sending status will not be pushed until next month.',
  'Please upload the BMP file!': 'Please upload the BMP file!',
  'Data is not format!': 'Data is not format',
  'Using push': 'Using push',
  'Max push per month': 'Max push per month',
  'Are you sure to leave this code detail?': 'Are you sure to leave this code detail?',
  'The changed content will not be save.': 'The changed content will not be save.',
  'No, cancel': 'No, cancel',
  invalidData: 'Invalid data',
  'Title max 240 length': 'Title max 240 length',
  'Copy completed': 'Copy completed',
  'Copy failed': 'Copy failed',
  Copy: 'Copy',
  Link: 'Link',
  'TextLengthExceededException: Maximum text length has been exceeded':
    'Polly does not read more than {number} characters.',
  'InvalidSsmlException: Invalid SSML request': 'Invalid SSML request',
  '5000 characters limit exceeded.':
    'Cloud Text-to-Speech does not read more than {number} characters.',
  'This project header is updated.': 'This project header is updated.',
  'This project header update failed.': 'This project header update failed.',
  'Please input your header': 'Please input your header',
  'Please input your footer': 'Please input your footer',
  'Embed code': 'Embed code',
  Options: 'Options',
  'Show header': 'Show header',
  'Show footer': 'Show footer',
  'Font color setting': 'Font color setting',
  'Color selection': 'Color selection',
  'Background color setting': 'Background color setting',
  Private: 'Private',
  Public: 'Public',
  'Edit the header': 'Edit the header',
  'Edit the footer': 'Edit the footer',
  'This code is public.': 'This code is public.',
  'This code is private.': 'This code is private.',
  'This code public failed.': 'This code public failed.',
  'This code private failed.': 'This code private failed.',
  'This project is public.': 'This project is public.',
  'This project is private.': 'This project is private.',
  'This project public failed.': 'This project public failed.',
  'This project private failed.': 'This project private failed.',
  Security: 'Security',
  'This project is private now, you need to change it to public to edit. Are you sure to change it to public?':
    'This project is private now, you need to change it to public to edit. Are you sure to change it to public?',
  'This code is private now, you need to change it to public to edit. Are you sure to change it to public?':
    'This code is private now, you need to change it to public to edit. Are you sure to change it to public?',
  'Do you also want to set all codes belong to this project to Public?':
    'Do you also want to set all codes belong to this project to Public?',
  'Do you also want to set all codes belong to this project to Private?':
    'Do you also want to set all codes belong to this project to Private?',
  'Publish URL': 'Publish URL',
  Open: 'Open',
  'This project setting is updated.': 'This project setting is updated.',
  'This project setting update failed.': 'This project setting update failed.',
  'This code setting is updated.': 'This code setting is updated.',
  'This code setting update failed.': 'This code setting update failed.',
  'Item order': 'Item order',
  'New arrival order': 'New arrival order',
  'Oldest first': 'Oldest first',
  'ID DESC': 'ID DESC',
  'ID ASC': 'ID ASC',
  'User setting': 'User setting',
  'Page language': 'Page language',
  'Show created date': 'Show created date',
  'Show share button': 'Show share button',
  'Show category': 'Show category',
  'Show category tag': 'Show category tag',
  'You can input a custom title for the filter category':
    'You can input a custom title for the filter category',
  'Category List': 'Category List',
  'Select or input custom category': 'Select or input custom category',
  'Custom title': 'Custom title',
  'Search category by': 'Search category by',
  'Mega menu': 'Mega menu',
  'Scroll down select': 'Scroll down select',
  'Search section': 'Search',
  'Display keyword search': 'Display keyword search',
  'Category management': 'Category management',
  'Add Root Category': 'Add Root Category',
  'Do you want to delete this Category?': 'Do you want to delete this Category?',
  Warning: 'Warning',
  'confirm delete':
    'The selected Category is containing code. | Do you still want to delete this Category?',
  'Delete failed!': 'Delete failed!',
  'Parent category must have at least 1 item!': 'Parent category must have at least 1 item!',
  'New node': 'New node',
  'New leaf': 'New leaf',
  'Add Tree Node': 'Add Tree Node',
  'Add Leaf Node': 'Add Leaf Node',
  editCategory: 'Edit',
  deleteCategory: 'Delete',
  'Must be 3 levels maximum!': 'Must be 3 levels maximum!',
  'Must be 50 item maximum!': 'Must be 50 item maximum!',
  'Background color for root category': 'Background color for root category',
  'Background color for sub category': 'Background color for sub category',
  'Font color for root category': 'Font color for root category',
  'Font color for sub category': 'Font color for sub category',
  'Format created date': 'Format created date',
  'PC Preview': 'PC Preview',
  'Mobile Preview': 'Mobile Preview',
  'Search by category': 'Search or input category',
  'Attached URL': 'Attached URL',
  '(If you set up a link, this link will be displayed as「More details」in the Uni-Voice Mobile app.)':
    '(If you set up a link, this link will be displayed as「More details」in the Uni-Voice Mobile app.)',
  warningContentLimit:
    'Warning: You have exceeded a limit of Code, the code content will be stored within the allowed limit. | If you are connected to the internet when scanning by app, it will read the full content, but if you are offline, it will only read the number of characters stored in the code.',
  'Preview the content stored in the code': 'Preview the content stored in the code',
  'No options available.': 'No options available.',
  'You can input a custom page title for the page':
    'You can input a custom page title for the page',
  'Page title': 'Page title',
  'Show title': 'Show title',
  'Show page title': 'Show page title',
  'Page title setting': 'Page title setting',
  'Show background color': 'Show background color',
  'Show border': 'Show border',
  'Show print button': 'Show print button',
  'This project is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'This project is private now, you need to change it to public to embed. Are you sure to change it to public?',
  Restore: 'Restore',
  'Archived projects': 'Archived projects',
  'Archived project list': 'Archived project list',
  'Archived code list': 'Archived code list',
  'Archived codes': 'Archived codes',
  archiveConfirm:
    'Do you want to archive this project? | Do you want to archive these {count} projects?',
  restoreConfirm:
    'Do you want to restore this project? | Do you want to restore these {count} projects?',
  ssmlIntroduction:
    'Speech Synthesis Markup Language (SSML) tags allow you to modify speech output, for example by selecting a Newscaster voice, changing the phonetic pronunciation of a word, or adding a pause.',
  'Advanced speak(SSML)': 'Advanced speak(SSML)',
  'SSML Enable': 'Using SSML to allow for more customization in your audio response',
  Text: 'Text',
  'Alt texts': 'Alt texts',
  'Attached photo': 'Attached photo',
  '(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)':
    '(If you set an image, it will be displayed as a thumbnail image of Reading Homepage)',
  'You can upload up to 10MB per file': 'You can upload up to 10MB per file',
  'Photo alt text is required': 'Photo alt text is required',
  'Publish info': 'Publish info',
  'Page template': 'Page template',
  'News(Card list)': 'News(Card list)',
  'News(Card view)': 'News(Card view)',
  'News(List view)': 'News(List view)',
  'News(Category)': 'News(Category)',
  'Page option': 'Page option',
  'Item display option': 'Item display option',
  'Show thumbnail': 'Show thumbnail',
  'Item naming': 'Item naming',
  'Search option': 'Search option',
  'Display category search': 'Display category search',
  'Thumbnail type': 'Thumbnail type',
  'Photo, image': 'Photo, image',
  'Sub directory name': 'Sub directory name',
  'This project footer is updated.': 'This project footer is updated.',
  'This project footer update failed.': 'This project footer update failed.',
  'This project sub directory name is updated.': 'This project sub directory name is updated.',
  'This project sub directory name update failed.':
    'This project sub directory name update failed.',
  'This project sub directory name is already registered.':
    'This project sub directory name is already registered.',
  'Click or drag file to this area to upload': 'Click or drag file to this area to upload',
  'Please input your image url': 'Please input your image url',
  'Image URL is invalid.': 'Image URL is invalid.',
  'By URL': 'By URL',
  'Search setting': 'Search setting',
  'Color setting': 'Color setting',
  'Created date setting': 'Created date setting',
  'Thumbnail setting': 'Thumbnail setting',
  'Category setting': 'Category setting',
  'View category setting': 'View category setting',
  'Header and Footer setting': 'Header and Footer setting',
  'Page display setting': 'Page display setting',
  'Publish URL setting': 'Publish URL setting',
  'Original domain setting': 'Original domain setting',
  'Examples: news, products, tickets ...': 'Examples: news, products, tickets ...',
  '*View past versions': '*View past versions',
  'Versions history': 'Versions history',
  'TTS player option': 'TTS player option',
  'Title setting': 'Title setting',
  'Color setting of texts area': 'Color setting of texts area',
  'TTS player display setting': 'TTS player display setting',
  'Page title color': 'Page title color',
  'Background color': 'Background color',
  'Heading color': 'Heading color',
  'Item title color': 'Item title color',
  'Font color for search title': 'Font color for search title',
  Photo: 'Photo',
  'Please input your project description': 'Please input your project description',
  Category: 'Category',
  'Advanced information': 'Advanced Information',
  'Basic information': 'Basic information',
  'JAN Codes': 'JAN Codes',
  '(Optional)': '(Optional)',
  '(The JAN code is displayed on products as a distribution code for products and is used in POS systems that are widely used mainly in convenience stores and supermarkets.)':
    '(The JAN code is displayed on products as a distribution code for products and is used in POS systems that are widely used mainly in convenience stores and supermarkets.)',
  'Item display setting': 'Item display setting',
  'Title color': 'Title color',
  '(If you register a JAN code as a voice code, the Uni-Voice Mobile App will scan the JAN code then display and read out the contents of this voice code)':
    '(If you register a JAN code as a voice code, the Uni-Voice Mobile App will scan the JAN code then display and read out the contents of this voice code)',
  'Delete selected code': 'Delete selected code',
  'Import all texts as a single Voice code': 'Import all texts as a single Voice code',
  'Import texts per page as multiple Voice codes': 'Import texts per page as multiple Voice codes',
  'Import texts with delimiter texts as multiple Voice codes':
    'Import texts with delimiter texts as multiple Voice codes',
  'Delimiter list': 'Delimiter list',
  'New delimiter': 'New delimiter',
  'Compose new message': 'Compose new message',
  'Page icon setting': 'Page icon setting',
  'Icon image (favicon)': 'Icon image (favicon)',
  '*You can enter "xx" for dynamic string replacement. Example: [String xx], [xx String], [String xx String]':
    '*You can enter "xx" for dynamic string replacement. Example: [String xx], [xx String], [String xx String]',
  'Order same as display order in code list screen':
    'Order same as display order in code list screen',
  'Import failed!': 'Import failed!',
  'Link to Voice Code': 'Link to Voice Code',
  'Link to messages': 'Link to messages',
  'Switch public/private': 'Switch public/private',
  'Set advanced information': 'Set advanced information',
  'Category hierarchy': 'Category hierarchy',
  'You can set category hierarchy to display': 'You can set category hierarchy to display',
  'Exclude delimiter': 'Exclude delimiter',
  'Jancode is already exists': 'Jancode is already exists',
  'Default Header': 'Default Header',
  'Default Footer': 'Default Footer',
  'The default header is updated.': 'The default header is updated.',
  'The default footer is updated.': 'The default footer is updated.',
  'The default header update failed.': 'The default header update failed.',
  'The default footer update failed.': 'The default footer update failed.',
  'Header & Footer Setting': 'Header & Footer Setting',
  'setting-header-footer': 'Header & Footer Setting',
  "In case of active, this default header will be used for the new page of this account, otherwise it will use the system's default header contents.":
    "In case of active, this default header will be used for the new page of this account, otherwise it will use the system's default header contents.",
  "In case of active, this default footer will be used for the new page of this account, otherwise it will use the system's default footer contents.":
    "In case of active, this default footer will be used for the new page of this account, otherwise it will use the system's default footer contents.",
  'This default header is activated.': 'This default header is activated.',
  'This default header is de-activated.': 'This default header is de-activated.',
  'This default footer is activated.': 'This default footer is activated.',
  'This default footer is de-activated.': 'This default footer is de-activated.',
  'Include all codes inside the project?': 'Include all codes inside the project?',
  'Duplicate Project': 'Duplicate Project',
  'Duplicate project successfully': 'Duplicate project successfully',
  'Duplicate project failed': 'Duplicate project failed',
  'Are you sure delete these codes?': 'Are you sure delete these {count} codes?',
  'Delete selected': 'Delete selected',
  'Delete all': 'Delete all',
  'Are you sure delete all codes?': 'Are you sure delete all codes?',
  'Reload list': 'Reload list',
  'You cannot import project in this file.': 'You cannot import project in this file.',
  'Import project': 'Import project',
  'Export project': 'Export project',
  'Aspect ratio of thumbnail': 'Aspect ratio of thumbnail',
  'Keep original ratio': 'Keep original ratio',
  'Apply fixed ratio': 'Apply fixed ratio',
  'Importing the project succeed.': 'Importing the project succeed.',
  'Importing the project failed.': 'Importing the project failed.',
  'Importing... Please wait for a little bit!': 'Importing... Please wait for a little bit!',
  'Exporting... Please wait for a little bit!': 'Exporting... Please wait for a little bit!',
  'Exporting the project succeed.': 'Exporting the project succeed.',
  'Exporting the project failed.': 'Exporting the project failed.',
  'Exporting the page succeed.': 'Exporting the page succeed.',
  'Exporting the page failed.': 'Exporting the page failed.',
  'Copy code to page successfully': 'Copy code to page {name} successfully',
  'Move code to page successfully': 'Move code to page {name} successfully',
  'Copy page to project successfully': 'Copy page to project {name} successfully',
  'Move page to project successfully': 'Move page to project {name} successfully',
  'You can not choose the default project!': 'You can not choose the default project!',
  'Choosing Project': 'Choosing Project',
  Move: 'Move',
  'This code is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'This code is private now, you need to change it to public to embed. Are you sure to change it to public?',
  Portals: 'Portals',
  Pages: 'Pages',
  'Page name': 'Page name',
  'Page compose': 'Page compose',
  'Import page': 'Import page',
  'Page list': 'Page list',
  'Edit page': 'Edit page',
  'Please input your page name': 'Please input your page name',
  'Please input your page description': 'Please input your page description',
  'Page name max length 50': 'Page name max length 50',
  'This page has been updated': 'This page has been updated',
  'This page update failed': 'This page update failed',
  'This project has been updated': 'This project has been updated',
  'This project update failed': 'This project update failed',
  'Do you also want to set all codes belong to this page to Public?':
    'Do you also want to set all codes belong to this page to Public?',
  'Do you also want to set all codes belong to this page to Private?':
    'Do you also want to set all codes belong to this page to Private?',
  'Duplicate page successfully': 'Duplicate page successfully',
  'Duplicate page failed': 'Duplicate page failed',
  'Do you want to duplicate this page?': 'Do you want to duplicate this page?',
  'Do you want to change the status of this page to private?':
    'Do you want to change the status of this page to private?',
  'Do you want to change the status of this page to public?':
    'Do you want to change the status of this page to public?',
  'Importing the page succeed.': 'Importing the page succeed.',
  'Importing the page failed.': 'Importing the page failed.',
  'Portal list': 'Portal list',
  'Compose portal': 'Compose portal',
  'Portal compose': 'Portal compose',
  'Portal name': 'Portal name',
  'Website editor': 'Website editor',
  'Item description color': 'Item description color',
  'Website configure': 'Website configure',
  'Add new category': 'Add new category',
  'Category name': 'Category name',
  'Please input category name!': 'Please input category name!',
  'This portal setting is updated.': 'This portal setting is updated.',
  'This portal setting update failed.': 'This portal setting update failed.',
  'This portal header is updated.': 'This portal header is updated.',
  'This portal header update failed.': 'This portal header update failed.',
  'This portal footer is updated.': 'This portal footer is updated.',
  'This portal footer update failed.': 'This portal footer update failed.',
  'This portal sub directory name is updated.': 'This portal sub directory name is updated.',
  'This portal sub directory name is already registered.':
    'This portal sub directory name is already registered.',
  'Category configure': 'Category configure',
  'Internal site': 'Internal site',
  'External site': 'External site',
  'Website setting': 'Website setting',
  'Category text color': 'Category text color',
  'Description color': 'Description color',
  'Show description': 'Show description',
  'Mega menu setting': 'Mega menu setting',
  Close: 'Close',
  Create: 'Create',
  'List items': 'List items',
  'Adding Sites': 'Adding Sites',
  'Select all': 'Select all',
  'Edit site': 'Edit site',
  'Archived pages': 'Archived pages',
  'Archived page list': 'Archived page list',
  'Codes title': 'Codes',
  'Please input your portal description': 'Please input your portal description',
  'Portal site for reading homepage': 'Portal site for reading homepage',
  Site: 'Site',
  'Clear form data': 'Clear form data',
  'Please input your portal site name!': 'Please input your portal site name!',
  'Portal detail': 'Portal detail',
  Lexicons: 'Lexicons',
  'Choosing lexicon will enable customize the pronunciation of words when reading content':
    'Choosing lexicon will enable customize the pronunciation of words when reading content',
  'The sites have been added!': 'The sites have been added!',
  'Adding sites failed!': 'Adding sites failed!',
  'This site has been updated!': 'This site has been updated!',
  'Updating site failed!': 'Updating site failed!',
  'This category has been updated!': 'This category has been updated!',
  'Updating category failed!': 'Updating category failed!',
  'Are you sure to delete this site?': 'Are you sure to delete this site?',
  'Please input your category name!': 'Please input your category name!',
  'Please input your description!': 'Please input your description!',
  'Please input your url!': 'Please input your url!',
  'Please input your title!': 'Please input your title!',
  'Compose page title': 'Compose page',
  'Compose page': 'Compose page',
  'Save page': 'Save page',
  Add: 'Add',
  'This page is not public, so that it can not be shown in the portal after picking up':
    'This page is not public, so that it can not be shown in the portal after picking up',
  'Do you want to jump to the copy(move) destination?':
    'Do you want to jump to the copy destination?|Do you want to jump to the move destination?',
  'Move to category': 'Move to category',
  'Crawl index ASC': 'Crawl index ASC',
  'Crawl index DESC': 'Crawl index DESC',
  'Page detail': 'Page detail',
  'Click to copy': 'Click to copy',
  PORTAL: 'PORTAL',
  CATALOG: 'CATALOG',
  'Include all page inside the project?': 'Include all page inside the project?',
  'Include all codes inside each page of the project?':
    'Include all codes inside each page of the project?',
  'This page is private now, you need to change it to public to embed. Are you sure to change it to public?':
    'This page is private now, you need to change it to public to embed. Are you sure to change it to public?',
  'Duplicate page': 'Duplicate page',
  PRIMARY: 'PRIMARY',
  NONE: 'NONE',
  'Change Page': 'Change Page',
  'Home page link': 'Home page link',
  'Sub Domain': 'Sub Domain',
  'Please input your sub domain': 'Please input your sub domain',
  'View page list': 'View page list',
  'This sub domain is already exist!': 'This sub domain is already exist!',
  'Select the template for your primary page. You can change the template later.':
    'Select the template for your primary page. You can change the template later.',
  'A Catalog page is a collection of codes that can be used to build a website or application. It is a great way to start a project.':
    'A Catalog page is a collection of codes that can be used to build a website or application. It is a great way to start a project.',
  'A portal is a collection of pages that can be used to build a website or application.':
    'A portal is a collection of pages that can be used to build a website or application.',
  'Include all codes inside the page?': 'Include all codes inside the page?',
  'Select the type of page you want to create. Additional configuration and integrations can be added later.':
    'Select the type of page you want to create. Additional configuration and integrations can be added later.',
  'Set as primary': 'Set as primary',
  'Set as primary successfully.': 'Set as primary successfully.',
  'Are you sure to change the primary page?': 'Are you sure to change the primary page?',
  'Page introduction': 'Page introduction',
  'Show introduction button': 'Show introduction button',
  'Please enter the description for this site': 'Please enter a description for this site',
  'Introduction content': 'Content',
  'Switch page': 'Switch page',
  'Export page': 'Export page',
  'This page is public.': 'This page is public.',
  'This page is private.': 'This page is private.',
  'This page public failed.': 'This page public failed.',
  'This page private failed.': 'This page private failed.',
  'This page setting is updated.': 'This page setting is updated.',
  'This page setting update failed.': 'This page setting update failed.',
  'Created page successfully.': 'Created page successfully.',
  'Select destination page': 'Select destination page',
  'Select destination project': 'Select destination project',
  'Archive successfully': 'Archive successfully',
  'Archive failed': 'Archive failed',
  'Restore successfully': 'Restore successfully',
  'Restore failed': 'Restore failed',
  'Sub domain must be string!': 'Sub domain must be string!',
  'Go to edit sub domain': 'Go to edit sub domain',
  'How to add a new category': 'How to add a new category',
  'Add to the beginning': 'Add to the beginning',
  'Add to the end': 'Add to the end',
  Catalog: 'Catalog',
  Portal: 'Portal',
  'Text align': 'Text align',
  Left: 'Left',
  Center: 'Center',
  Right: 'Right',
  'Font size': 'Font size',
  Big: 'Big',
  'Menu setting': 'Menu setting',
  'Show menu': 'Show menu',
  'Page logo setting': 'Page logo setting',
  'Show logo': 'Show logo',
  'Logo image': 'Logo image',
  'Logo caption': 'Logo caption',
  'Do you want to archive the selected page?': 'Do you want to archive the selected page?',
  'Do you want to restore the selected page?': 'Do you want to restore the selected page?',
  'Primary page cannot be archived.': 'Primary page cannot be archived.',
  'Primary page cannot be moved.': 'Primary page cannot be moved.',
  'This slug is already registered': 'This page sub directory name is already registered.',
  'This page sub directory name is updated.': 'This page sub directory name is updated.',
  'X-Small': 'X-Small',
  Small: 'Small',
  Medium: 'Medium',
  Large: 'Large',
  'X-Large': 'X-Large',
  'Icon view': 'Icon view',
  'Menu view': 'Menu view',
  'Search page by name': 'Search page by name',
  'Do you want to change the status of this project to public?':
    'Do you want to change the status of this project to public?',
  'Do you want to change the status of this project to private?':
    'Do you want to change the status of this project to private?',
  'This action will change the visibility of all pages in this project. Are you sure you want to continue?':
    'This action will change the visibility of all pages in this project. Are you sure you want to continue?',
  'This action will change the visibility of all pages in this project.':
    'This action will change the visibility of all pages in this project.',
  'This action will change the visibility of the parent project of this page to public. Do you want to continue?':
    'This action will change the visibility of the parent project of this page to public. Do you want to continue?',
  'Include all codes inside this page?': 'Include all codes inside this page?',
  jpn: 'Japanese',
  eng: 'English',
  chi: 'Simplified Chinese',
  zho: 'Traditional Chinese',
  kor: 'Korean',
  fre: 'French',
  ger: 'German',
  spa: 'Spanish',
  ita: 'Italian',
  por: 'Portuguese',
  rus: 'Russian',
  tai: 'Thai',
  vie: 'Vietnamese',
  ind: 'Indonesian',
  ara: 'Arabic',
  may: 'Malay',
  hin: 'Hindi',
  tgl: 'Tagalog',
  dut: 'Dutch',
  'Create code content': 'Create code content',
  'Plain Text version': 'Plain Text version',
  'This content is embedded into Univoice Code and will be used in no internet case':
    'This content is embedded into Univoice Code and will be used in no internet case',
  'HTML Rich version': 'HTML Rich version',
  'This content is kept in Univoice Backend and will be downloaded to display when the user scans the code':
    'This content is kept in Univoice Backend and will be downloaded to display when the user scans the code',
  'Do you want to revert the previous version?': 'Do you want to revert the previous version?',
  'Old version': 'Old version',
  'New version': 'New version',
  'Previous version': 'Previous version',
  Revert: 'Revert',
  'Preview code content': 'Preview code content',
  'Preview previous version': 'Preview previous version',
  'Create from scratch': 'Create from scratch',
  'Create from an existing site': 'Create from an existing site',
  'Import file or input url': 'Import file or input url',
  'Pick by browser': 'Pick by browser',
  'The input is not valid URL!': 'The input is not valid URL!',
  'Please input your URL!': 'Please input your URL!',
  'Would you like to limit the scope of content in the original site instead of taking it all?':
    'Would you like to limit the scope of content in the original site instead of taking it all?',
  'This is a feature that allows you to limit the scope of content in the original site instead of taking it all.':
    'This is a feature that allows you to limit the scope of content in the original site instead of taking it all.',
  'Which needs Chrome Extension to enable. Please access':
    'Which needs Chrome Extension to enable. Please access',
  'to install if not yet.': 'to install if not yet.',
  Or: 'Or',
  'Input url': 'Input url',
  'Imported content!': 'Imported content!',
  'Preview content': 'Preview content',
  'Pickup content': 'Pickup content',
  'Picked up content successfully!': 'Picked up content successfully!',
  "Can't pick up the content from this site!": "Can't pick up the content from this site!",
  "Don't ask me again": "Don't ask me again",
  'Containing catalog pages': 'Containing catalog pages',
  'Include all codes inside the catalog pages?': 'Include all codes inside the catalog pages?',
  'This action will change the visibility of the parent project of selected pages to public. Do you want to continue?':
    'This action will change the visibility of the parent project of selected pages to public. Do you want to continue?',
  'This action will change the visibility of all pages in selected projects. Are you sure you want to continue?':
    'This action will change the visibility of all pages in selected projects. Are you sure you want to continue?',
  'Pin to top': 'Pin to top',
  Unpin: 'Unpin',
  'This page is pinned or primary page. You can not drag and drop it.':
    'This page is pinned or primary page. You can not drag and drop it.',
  'Codes are created in the order shown. You can change the order by dragging.':
    'Codes are created in the order shown. You can change the order by dragging.',
  'Uni-Voice': 'Uni-Voice',
  'Open link': 'Open link',
  'This page is private now, you need to change it to public to edit website!':
    'This page is private now, you need to change it to public to edit website!',
  'Item background': 'Item background',
  'Preview rich content': 'Preview rich content',
  'Import category': 'Import category',
  'Importing category successfully.': 'Importing category successfully.',
  'Importing category failed.': 'Importing category failed.',
  'Export category': 'Export category',
  'Exporting category successfully.': 'Exporting category successfully.',
  'Exporting category failed.': 'Exporting category failed.',
  'Speak category tag': 'Speak category tag',
  Fold: 'Fold',
  Unfold: 'Unfold',
  'This is an archived page': 'This is an archived page',
  'The source page is currently private!': 'The source page is currently private!',
  'Show numbering': 'Show numbering',
  General: 'General',
  Schedule: 'Schedule',
  Visible: 'Visible',
  Hidden: 'Hidden',
  Parameters: 'Parameters',
  'Publish status': 'Publish status',
  'Scheduling in progress': 'Scheduling in progress',
  'Scheduled for': 'Scheduled for',
  schedulePublishChangeHTML: 'Change from {fromStatus} to {toStatus}',
  'Time remaining': 'Time remaining',
  'Successfully scheduled': 'Successfully scheduled',
  'Completed at': 'Completed at',
  'Re-schedule': 'Re-schedule',
  'Schedule setting': 'Schedule setting',
  'Setup schedule': 'Setup schedule',
  'Cancel schedule': 'Cancel schedule',
  'Change the publish status of all pages and codes in this project.':
    'Change the publish status of all pages and codes in this project.',
  'Do not change the publish status of pages and codes (keep current settings).':
    'Do not change the publish status of pages and codes (keep current settings).',
  'SVG image is not supported!': 'SVG image is not supported!',
  'Are you sure you want to use this url?': 'Are you sure you want to use this url?',
  'Column setting': 'Column setting',
  'Type of column': 'Type of column',
  'Number of column': 'Number of column',
  Fixed: 'Fixed',
  Flexible: 'Flexible',
  Password: 'Password',
  'Please input the password': 'Please input the password',
  'Enable authentication': 'Enable authentication',
  'Password Setting': 'Password Setting',
  'When set flexibly, it adjusts the number of columns displayed according to the number of categories.':
    'When set flexibly, it adjusts the number of columns displayed according to the number of categories.',
  'Global menu': 'Global Menu',
  Tags: 'Tags',
  'Tag setting': 'Tag setting',
  'Show tag': 'Show tag',
  'Speak tag': 'Speak tag',
  'Font color for tag': 'Font color for tag',
  'Background color for tag': 'Background color for tag',
  '(Tags are used to classify codes. It can be used for several purposes such as subcategories, search, etc.)':
    '(Tags are used to classify codes. It can be used for several purposes such as subcategories, search, etc.)',
  'You can add tags by typing them in the box below.':
    'You can add tags by typing them in the box below.',
  'Please enter a tag': 'Please enter a tag',
  'Align category-item': 'Align category-item',
  'Vertical-Horizontal': 'Vertical-Horizontal',
  'Horizontal-Vertical': 'Horizontal-Vertical',
  Configuration: 'Configuration',
  Conditions: 'Conditions',
  'Auto-push message configuration': 'Auto-push message configuration',
  'Push after': 'Push after',
  'Owner auto approval time': 'Owner auto approval time',
  'Add new page condition': 'Add new page condition',
  'Please select target page': 'Please select target page',
  'Field name': 'Field name',
  String: 'String',
  Array: 'Array',
  Operator: 'Operator',
  'Equal to': 'Equal to',
  Contains: 'Contains',
  'Similar to': 'Similar to',
  Value: 'Value',
  'Save message configuration': 'Save message configuration',
  'Invalid target data': 'Invalid target data',
  'Invalid schedule configuration': 'Invalid schedule configuration',
  'Invalid page condition': 'Invalid page condition',
  Setting: 'Setting',
  'Enable auto-push': 'Enable auto-push',
  'This project is not have permission to use this feature':
    'This project is not have permission to use this feature',
  'Please contact your administrator to get more information':
    'Please contact your administrator to get more information',
  'Or condition': 'Or condition',
  'minutes later': 'minutes later',
  'Revision history': 'Revision history',
  'Release this version': 'Release this version',
  'Optionally, you can add a note for this version for revision history.':
    'Optionally, you can add a note for this version for revision history.',
  'This version is for ...': 'This version is for ...',
  'Revisions for {name}': 'Revisions for {name}',
  'Version {version}': 'Version {version}',
  'Date time': 'Date time',
  Note: 'Note',
  Revision: 'Revision',
  'Current revision': 'Current revision',
  'Are you sure to restore this version?': 'Are you sure to restore this version?',
  'Discard changes': 'Discard changes',
  'Discard changes successfully.': 'Discard changes successfully.',
  'Are you sure to discard changes?': 'Are you sure to discard changes?',
  'Save successfully.': 'Save successfully.',
  'Save failed.': 'Save failed.',
  '*This is the URL of the public version': '*This is the URL of the public version',
  '*This is the category configure of the public version':
    '*This is the category configure of the public version',
  'Public version': 'Public version',
  'Draft version': 'Draft version',
  'Important Note': 'Important Note',
  Release: 'Release',
  'This website is not public yet': 'This website is not public yet',
  'Please edit your website first before pubic this page.':
    'Please edit your website first before pubic this page.',
  'New version saved!': 'New version saved!',
  'New version is released!': 'New version is released!',
  'Save and Update': 'Save and Update',
  'There are changes that have not been saved.': 'There are changes that have not been saved.',

  'There are changes that have not been updated.': 'There are changes that have not been updated.',
  'Show more': 'Show more',
  'Are you sure you want to add this page to the portal?':
    'Are you sure you want to add this page to the portal?',
  'You are trying to import a project or page that contains web content (HTML), but your account does not currently support the web content version of the voice code. Only plain text data will be imported.':
    'You are trying to import a project or page that contains web content (HTML), but your account does not currently support the web content version of the voice code. Only plain text data will be imported.',
  'Required only for private page': 'Required only for private page',
  'Follow webhook instructions': 'Follow webhook instructions'
};
